import { Badge, Box, Center, Flex, Grid, Group, Paper, RingProgress, Skeleton, Space, Tabs, Text } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { createStyles } from "@mantine/emotion";
import { IconArrowDown, IconArrowUp, IconCalendar, IconCash, IconCoins, IconSquareRoundedArrowDown, IconSquareRoundedArrowUp } from "@tabler/icons-react";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import useSwr from "swr";
import { dashboardApi } from "../../apis";
import { usePermission } from "../../components/permission";
import { currencyFormat } from "../../utils";
import { RecentExpenditureList } from "./recent-expenditure-list";
import { RecentRevenueTransactionsList } from "./recent-revenue-transactions-list";

const now = new Date();

export function Dashboard() {
  const { classes, theme } = useStyles();
  const PAY_DASH = usePermission("PAY_DASH");
  const [filter, setFilter] = useState<any>({
    startDate: dayjs(now).subtract(14, "day").format(),
    date: dayjs(now).format(),
    endDate: dayjs(now).format(),
    structureMonth: dayjs(now).format(),
  });
  const { data = null } = useSwr<any>(
    PAY_DASH.hasAccess && `/biz/dashboard/main?${JSON.stringify({ ...filter })}`,
    async () =>
      PAY_DASH.hasAccess && (await dashboardApi.main({ ...filter, startDate: dayjs(filter.startDate).format(), endDate: dayjs(filter.endDate).format() })),
    {
      fallback: [],
    },
  );

  // dashboardApi

  const getOptions = ({ data }: { data: any }) => ({
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
      align: "left",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: data?.byInterval?.map((it: any) => {
          return it.date;
        }),
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        title: {
          text: "",
          style: {
            color: "green",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "black",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      y: 18,
    },
    series: [
      {
        name: "Орлогын гүйлгээ",
        color: "#00539C",
        type: "column",
        data: data?.byInterval?.map((it: any) => {
          return it.incomeAmount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Зарлагын гүйлгээ",
        color: "#EEA47F",
        type: "column",
        data: data?.byInterval?.map((it: any) => {
          return it.outcomeAmount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Орлогын гүйлгээ тоо",
        type: "spline",
        color: "#00539C",
        data: data?.byInterval?.map((it: any) => {
          return it.incomeCount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Зарлагын гүйлгээ тоо",
        type: "spline",
        color: "#EEA47F",
        data: data?.byInterval?.map((it: any) => {
          return it.outcomeAmount;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  });

  if (!PAY_DASH.hasAccess) return <Paper p={"sm"}>{PAY_DASH.accessDenied()}</Paper>;
  else
    return (
      <div className={classes.root}>
        <Group align="apart" mb={"xs"}>
          <Text size={"xl"} fw={600}>
            Тоон тойм
          </Text>
          <DatePickerInput
            excludeDate={(date) => {
              return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
            }}
            value={new Date(filter.date)}
            onChange={(e: any) => {
              if (e) {
                setFilter({
                  ...filter,
                  date: e,
                });
              } else {
                setFilter({
                  ...filter,
                  date: dayjs().format("YYYY-MM-DD"),
                });
              }
            }}
            style={{ borderRadius: "5px" }}
            variant="unstyled"
            bg={theme.colors.blue[0]}
            maw={"300px"}
            w={"100%"}
            placeholder={`${dayjs().format("YYYY-MM-DD")}`}
            leftSection={<IconCalendar size={"1.2rem"} />}
            valueFormat="YYYY-MM-DD"
          />
        </Group>

        <Grid>
          <>
            {data?.numberSurvey?.map((stat: any, index: number) => {
              const DiffIcon = stat.changedAmount > 0 ? IconArrowUp : IconArrowDown;

              return (
                <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 3 }} key={index}>
                  <Paper withBorder key={stat.key} radius={"md"}>
                    <Group p={"sm"} pb={"xs"}>
                      {index === 0 && <IconSquareRoundedArrowDown size={"1.2rem"} color={theme.colors.gray[6]} />}
                      {index === 1 && <IconSquareRoundedArrowUp size={"1.2rem"} color={theme.colors.gray[6]} />}
                      {index === 2 && <IconCoins size={"1.2rem"} color={theme.colors.gray[6]} />}
                      {index === 3 && <IconCash size={"1.2rem"} color={theme.colors.gray[6]} />}
                      <Text fz={"sm"} fw={600} c={theme.colors.gray[6]}>
                        {stat.name}
                      </Text>
                    </Group>
                    <Group>
                      <div className={classes.grdnt}></div>
                      <Space />
                      <Text fz={"xl"} fw={700}>
                        {currencyFormat(stat.amount)}
                      </Text>
                    </Group>
                    <Group align="right" p={"sm"}>
                      <Text size={"xs"} c={theme.colors.gray[6]}>
                        өмнөх сараас
                      </Text>
                      <Badge color={stat.diff > 0 ? "green" : "red"} className={stat.changedAmount > 0 ? classes.green : classes.red}>
                        <Flex align={"center"} gap={8}>
                          <DiffIcon size={"0.8rem"} stroke={3} />
                          <Text>{Math.abs(stat.changedAmount)} ₮</Text>
                        </Flex>
                      </Badge>
                    </Group>
                  </Paper>
                </Grid.Col>
              );
            }) || (
              <>
                {[1, 2, 3, 4].map((index: number) => {
                  return (
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
                      <Skeleton h={"121px"} w={"100%"} />
                    </Grid.Col>
                  );
                })}
              </>
            )}
          </>
          <Grid.Col>
            <Tabs variant="pills" defaultValue={"1"}>
              <Tabs.List>
                <Tabs.Tab value="1">Жишээ</Tabs.Tab>
                <Tabs.Tab value="2">Бодит</Tabs.Tab>
              </Tabs.List>
              <Space h={"xs"} />
              <Paper withBorder py={"xs"} mih={"480px"}>
                <Group align="apart">
                  <Group>
                    <div className={classes.grdnt}></div>
                    <Text component="h1">Платформоор хийгдсэн гүйлгээ</Text>
                  </Group>
                  <Box mr={"lg"}>
                    <DatePickerInput
                      type="range"
                      excludeDate={(date) => {
                        return dayjs(date).isAfter(dayjs(), "day");
                      }}
                      value={[filter.startDate ? new Date(filter.startDate) : null, filter.endDate ? new Date(filter.endDate) : null]}
                      onChange={(e) => {
                        if (e[0] && e[1]) {
                          setFilter({
                            ...filter,
                            startDate: dayjs(e[0]).format("YYYY-MM-DD"),
                            endDate: dayjs(e[1]).format("YYYY-MM-DD"),
                          });
                        } else if (!e[0] && !e[1]) {
                          setFilter({
                            ...filter,
                            startDate: dayjs().subtract(14, "day").format("YYYY-MM-DD"),
                            endDate: dayjs().format("YYYY-MM-DD"),
                          });
                        }
                      }}
                      style={{ borderRadius: "5px" }}
                      variant="unstyled"
                      bg={theme.colors.blue[0]}
                      placeholder="Огноо хайх"
                      leftSection={<IconCalendar />}
                      valueFormat={`YYYY-MM-DD`}
                      px="md"
                    />
                  </Box>
                </Group>
                <Tabs.Panel value="1">
                  <Paper px={"lg"}>
                    <HighchartsReact highcharts={Highcharts} options={getOptions({ data: { byInterval } })} />
                    <Group align="right" mt={10}>
                      <Text fz={"sm"} c="dimmed" bg={"white"} style={{ zIndex: 2 }}>
                        Хэмжих нэгж: Сая төгрөгөөр
                      </Text>
                    </Group>
                    <Space h={"xs"} />
                  </Paper>
                </Tabs.Panel>
                <Tabs.Panel value="2">
                  <Paper px={"lg"}>
                    <HighchartsReact highcharts={Highcharts} options={getOptions({ data })} />
                    <Group align="right" mt={10}>
                      <Text fz={"sm"} c="dimmed" bg={"white"} style={{ zIndex: 2 }}>
                        Хэмжих нэгж: Сая төгрөгөөр
                      </Text>
                    </Group>
                  </Paper>
                </Tabs.Panel>
              </Paper>
            </Tabs>
          </Grid.Col>

          <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 9 }}>
            <Paper withBorder py="lg" mb="lg">
              <Group align="apart">
                <Group align="center">
                  <div className={classes.grdnt}></div>
                  <Text component="h1">Сүүлд хийгдсэн орлогын гүйлгээ</Text>
                </Group>
              </Group>
              <Paper px="lg">
                <RecentRevenueTransactionsList data={data?.recentIncomes || []} />
              </Paper>
            </Paper>

            <Paper withBorder py="lg">
              <Group align="apart">
                <Group align="center">
                  <div className={classes.grdnt}></div>
                  <Text component="h1">Сүүлд хийгдсэн зарлагын гүйлгээ</Text>
                </Group>
              </Group>
              <Paper px={"lg"}>
                <RecentExpenditureList data={data?.recentOutcomes || []} />
              </Paper>
            </Paper>
          </Grid.Col>

          <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
            <Group mb={"sm"}>
              <Text fz={"lg"} fw={600}>
                Орлогын бүтэц
              </Text>
              <DatePickerInput
                valueFormat="YYYY-MM-DD"
                excludeDate={(date) => {
                  return dayjs(date).format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD");
                }}
                placeholder={`${dayjs(new Date()).format("YYYY-MM-DD")}`}
                value={new Date(filter?.structureMonth)}
                onChange={(e) => {
                  setFilter({ ...filter, structureMonth: e || dayjs(new Date()).format() });
                }}
              />
            </Group>

            <Flex direction={"column"} gap={"xs"}>
              {data?.revenueStructure?.map((item: any, index: number) => {
                return (
                  <Paper py={"xs"} px="md" withBorder key={index}>
                    <Group align="apart">
                      <div>
                        <Text fz={"sm"} c="gray">
                          {item.name}
                        </Text>
                        <Text fw={600}>
                          {currencyFormat(item.amount)}{" "}
                          <Text fw={400} c="gray" component="span">
                            / {item.count} ш
                          </Text>{" "}
                        </Text>
                      </div>
                      <RingProgress
                        size={70}
                        sections={[{ value: 40, color: "primary" }]}
                        thickness={5}
                        roundCaps
                        rootColor="blue.0"
                        label={
                          <Center>
                            <Text fz={"xs"} fw={600}>
                              {item.percent} %
                            </Text>
                          </Center>
                        }
                      />
                    </Group>
                  </Paper>
                );
              })}
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
    );
}

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  grdnt: {
    background: theme.colors.primary[6],
    minHeight: "30px",
    width: "5px",
    borderRadius: 5,
    marginLeft: -3,
  },
  green: {
    borderColor: theme.colors.green[6],
  },
  red: {
    borderColor: theme.colors.red[6],
  },
}));

const byInterval = [
  {
    date: "III.06",
    incomeAmount: 10,
    incomeCount: 5,
    outcomeAmount: 12,
    outcomeCount: 5,
  },
  {
    date: "III.07",
    incomeAmount: 3,
    incomeCount: 5,
    outcomeAmount: 6,
    outcomeCount: 7,
  },
  {
    date: "III.08",
    incomeAmount: 2,
    incomeCount: 3,
    outcomeAmount: 4,
    outcomeCount: 5,
  },
  {
    date: "III.09",
    incomeAmount: 2,
    incomeCount: 3,
    outcomeAmount: 4,
    outcomeCount: 5,
  },
  {
    date: "III.10",
    incomeAmount: 2,
    incomeCount: 3,
    outcomeAmount: 4,
    outcomeCount: 5,
  },
  {
    date: "III.11",
    incomeAmount: 2,
    incomeCount: 3,
    outcomeAmount: 4,
    outcomeCount: 5,
  },
  {
    date: "III.12",
    incomeAmount: 3,
    incomeCount: 4,
    outcomeAmount: 5,
    outcomeCount: 6,
  },
  {
    date: "III.13",
    incomeAmount: 1,
    incomeCount: 2,
    outcomeAmount: 3,
    outcomeCount: 4,
  },
  {
    date: "III.14",
    incomeAmount: 1,
    incomeCount: 2,
    outcomeAmount: 3,
    outcomeCount: 4,
  },
  {
    date: "III.15",
    incomeAmount: 4,
    incomeCount: 5,
    outcomeAmount: 6,
    outcomeCount: 7,
  },
  {
    date: "III.16",
    incomeAmount: 1,
    incomeCount: 1,
    outcomeAmount: 3,
    outcomeCount: 3,
  },
  {
    date: "III.17",
    incomeAmount: 2,
    incomeCount: 2,
    outcomeAmount: 10,
    outcomeCount: 10,
  },
  {
    date: "III.18",
    incomeAmount: 3,
    incomeCount: 3,
    outcomeAmount: 2,
    outcomeCount: 2,
  },
  {
    date: "III.19",
    incomeAmount: 1,
    incomeCount: 1,
    outcomeAmount: 1,
    outcomeCount: 1,
  },
];
