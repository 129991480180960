import { ActionIcon, Avatar, Badge, Flex, Text, Tooltip } from "@mantine/core";
import { IconFileReport, IconPhoto } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AccountApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ColumnType, Table } from "../../components/table";
import { IBankList } from "../../interfaces/IBankLIst";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";

export const HistoryList = () => {
  const breadcrumbs = useBreadcrumb();
  const tableRef = React.useRef<any>(null);
  const [filters] = React.useState({ query: "" });
  const { bankNames } = useSelector((state: { general: IGeneral }) => state.general);
  const navigate = useNavigate();
  const PAY_TRX = usePermission("PAY_TRX");

  const currenciesTag = (val: string) => {
    switch (val) {
      case "TUGRUG": {
        return <Text size="sm">{"Төгрөг"}</Text>;
      }
      case "DOLLAR": {
        return <Text size="sm">{"Доллар"}</Text>;
      }
      default: {
        return <Text size="sm">{val ?? "-"}</Text>;
      }
    }
  };

  const columns = useHeader({
    currenciesTag,
    bankNames,
    onClick: (key, record) => {
      switch (key) {
        case "details":
          navigate(`/history/${record?.id}`, { state: record });
          break;
        default:
          console.log("!");
      }
    },
  });

  return (
    <PageLayout title="DeHUB гүйлгээний түүх" subTitle="DeHUB гүйлгээний түүх харах данс сонгоно уу" breadcrumb={breadcrumbs}>
      {PAY_TRX.hasAccess ? (
        <Table ref={tableRef} name="account.list" columns={columns} filters={filters} loadData={(data) => AccountApi.list(data!)} />
      ) : (
        PAY_TRX.accessDenied()
      )}
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  currenciesTag,
  bankNames,
}: {
  onClick: (key: string, record: IBankList) => void;
  currenciesTag: (key: string) => JSX.Element;
  bankNames: IReference[];
}): ColumnType<IBankList>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip position="bottom" label="Түүх үзэх">
          <ActionIcon color="" variant="light" onClick={() => onClick("details", record)}>
            <IconFileReport />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
  {
    title: "Банкны нэр",
    render: (record) => (
      <Flex gap={10} align="center">
        <Avatar radius="xs" src={record?.icon ?? "-"}>
          <IconPhoto />
        </Avatar>
        <Text w={"max-content"} size="sm">
          {bankNames.find((item: IReference) => item.code === record?.bankName)?.name ?? "-"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "number",
    render: (record) => <Text size="sm">{record?.number ?? "-"}</Text>,
  },
  {
    title: "Дансны нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => <Text size="sm">{record?.name ?? "-"}</Text>,
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => {
      return currenciesTag(record?.currency);
    },
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return record?.isActive === true ? (
        <Badge key={5} color="green" size="md" radius="sm" variant="outline">
          Идэвхтэй
        </Badge>
      ) : (
        <Badge key={6} color="gray" size="md" radius="sm" variant="outline">
          Идэвхгүй
        </Badge>
      );
    },
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => <Text size="sm">{record?.business.regNumber ?? "-"}</Text>,
  },
  {
    title: "Партнерийн нэр",
    sorter: true,
    dataIndex: "itemStatus",
    render: (record) => {
      return (
        <div>
          <Text c="indigo" size="sm">
            #{record?.business.partner.refCode}
          </Text>
          <Text size="sm">{record?.business.partner.businessName}</Text>
        </div>
      );
    },
  },
  {
    title: "Бизнесийн нэр",
    sorter: true,
    dataIndex: "quantity",
    render: (record) => {
      return record?.business ? (
        <div>
          <Text c="indigo" size="sm">
            #{record?.business?.refCode ?? "-"}
          </Text>
          <Text size="sm">{record?.business?.profileName ?? "-"}</Text>
        </div>
      ) : (
        "-"
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "#",
    label: "Эхлэл",
  },
  {
    to: "/",
    label: "Төлбөр тооцоо",
  },
  {
    label: "DeHUB гүйлгээний түүх",
  },
];
